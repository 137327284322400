<template lang="pug">
    div.row.justify-content-between
      div.col
        h5 Subscription
        button.btn.btn-primary(v-if="ss.length===0", type="button", @click="get") Load
        div.table-responsive(v-else)
          table.table.table-sm.ss-table
            thead
              tr
                th ID
                th Status
                th Period
                th Plan
                th Project
                th Amount
                th Next
                th More
            tbody
              tr(v-for="s in ss", :key="s.id")
                td {{ s.id }}
                td {{ status[s.status] }}
                td {{ s.start | moment }} ~ {{ s.end | moment }}
                td
                  a(v-if="s.plan==2", @click="show_custom(s.options)") {{ s.cycle==1? "(月)":"(年)"}} {{ plans[s.plan] }}
                  span(v-else) {{ s.cycle==1? "(月)":"(年)"}} {{ plans[s.plan] }}
                td
                  a(v-if="s.project", @click="show_project(s.project)") {{ s.project? s.project.label:"" }}
                td {{ s.currency=="jpy"? `￥${s.amount.toLocaleString()}`:`$${(s.amount/100).toLocaleString()}` }}
                td 
                  span(v-if="s.next_plan != null")
                    a(v-if="s.next_plan==0", @click="show_project(s.next_project)") {{ s.next_project? s.next_project.label:"" }}
                    span(v-else) {{ s.next_cycle==1? "(月)":"(年)"}} {{ plans[s.next_plan] }}
                td 
                  a(@click="show_detail(s)") More
      div.d-none#subscription-detail
        div.mb-3(v-if="detail.plan!=0 && detail.plan!=1")
          label Options
          table.table.table-sm
            tbody
              tr
                th Site_5
                td {{detail.options.Site_5}}
              tr
                th Interval10_5
                td {{detail.options.Interval10_5}}
              tr
                th Interval1_5
                td {{detail.options.Interval1_5}}
              tr
                th Screenshot_5
                td {{detail.options.Screenshot_5}}
              tr
                th LineNotify
                td {{detail.options.LineNotify}}
              tr
                th MailAddress_1
                td {{detail.options.MailAddress_1}}
              tr
                th RandomIP_1
                td {{detail.options.RandomIP_1}}
              tr
                th RandomIP_5
                td {{detail.options.RandomIP_5}}
        div.mb-3(v-if="detail.next_plan != null")
          label NextOptions
          table.table.table-sm
            tbody
              tr
                th Site_5
                td {{detail.next_options.Site_5}}
              tr
                th Interval10_5
                td {{detail.next_options.Interval10_5}}
              tr
                th Interval1_5
                td {{detail.next_options.Interval1_5}}
              tr
                th Screenshot_5
                td {{detail.next_options.Screenshot_5}}
              tr
                th LineNotify
                td {{detail.next_options.LineNotify}}
              tr
                th MailAddress_1
                td {{detail.next_options.MailAddress_1}}
              tr
                th RandomIP_1
                td {{detail.next_options.RandomIP_1}}
              tr
                th RandomIP_5
                td {{detail.next_options.RandomIP_5}}




        table.table.table-sm
          tbody
            tr
              th Created
              td {{moment(new Date(detail.created*1000)).format('YYYY/MM/DD HH:mm')}}
            tr
              th Updated
              td {{moment(new Date(detail.updated*1000)).format('YYYY/MM/DD HH:mm')}}
            tr
              th Stripe_PI_ID
              td {{detail.stripe_pi_id}}
        div
          label Detail
          textarea.form-control(rows="3", readonly) {{JSON.stringify(detail.detail,null,2)}}

</template>


<script>
import moment from 'moment';

export default {
  name:"Subscriptions",
  props:[ "user_id" ],
  data (){
    return {
      ss:[],
      status:[
        "",
        "Trial",
        "InvoiceOpen",
        "Active",
        "End",
        "Upgraded",
        "Moratorium",
        "Suspended",
        "Canceled",
        "Proration",

      ],
      plans:[
        "Project",
        "Cancel",
        "Customize",
        "Basic5",
        "Basic5_1min",
        "Basic25",
        "Basic25_1min_5",
        "Basic25_1min_10",
        "Basic25_1min_15",
        "Basic25_1min_20",
        "Basic25_1min_25",
        "Advanced",
      ],
      detail_modal: null,
      detail: {
        options:{},
        next_options:{}
      },
      moment: moment
    }
  },
  mounted (){
    this.detail_modal = document.getElementById("subscription-detail");
    this.detail_modal.remove();
    this.detail_modal.classList.remove("d-none");
  },
  methods: {
    get (){
      this.axios.get("/api/v2/subsc?id="+this.user_id)
      .then((res)=>{
        this.ss = res.data;
      })
      .catch((e)=>{
        console.log(e);
        console.log(e.response);
        this.$swal("","","error");
      });
    },
    show_detail(s) {
      this.detail = s;
      this.$swal({ content: this.detail_modal });
    },
    show_custom(options){
      let html = '<table class="table table-sm"><tbody>';
      html += "<tr><th>Site_5</th><td>"+ options.Site_5 + "</td></tr>";
      html += "<tr><th>Interval10_5</th><td>"+ options.Interval10_5 + "</td></tr>";
      html += "<tr><th>Interval1_5</th><td>"+ options.Interval1_5 + "</td></tr>";
      html += "<tr><th>Screenshot_5</th><td>"+ options.Screenshot_5 + "</td></tr>";
      html += "<tr><th>LineNotify</th><td>"+ options.LineNotify + "</td></tr>";
      html += "<tr><th>MailAddress_1</th><td>"+ options.MailAddress_1 + "</td></tr>";
      html += "<tr><th>RandomIP_1</th><td>"+ options.RandomIP_1 + "</td></tr>";
      html += "</tbody></table>"
      let div = document.createElement("div");
      div.innerHTML = html;
      this.$swal({ content:div });
    },
    show_more(s){
      let html = '<table class="table tabl"><tbody>';
      html += "<tr><th>Created</th><td>"+ moment(new Date(s.created*1000)).format('YYYY/MM/DD HH:mm') +"</td></tr>";
      html += "<tr><th>Updated</th><td>"+ moment(new Date(s.updated*1000)).format('YYYY/MM/DD HH:mm') +"</td></tr>";
      html += "<tr><th>Stripe_PI_ID</th><td>"+ s.stripe_pi_id + "</td></tr>";
      html += "</tbody></table>"
      html += "<label>Detail</label>";
      html += '<textarea class="form-control" rows="3" readonly>'+ JSON.stringify(s.detail,null,2) + '</textarea>';
      let div = document.createElement("div");
      div.innerHTML = html;
      this.$swal({ content:div });
    },
    show_project(project){
      let html = '<h5>'+project.id+':'+project.label+'</h5><table class="table table-sm"><tbody>';
      html += "<tr><th>60min</th><td>"+ project.num_60min + "</td></tr>";
      html += "<tr><th>10min</th><td>"+ project.num_10min + "</td></tr>";
      html += "<tr><th>1min</th><td>"+ project.num_1min + "</td></tr>";
      html += "<tr><th>Screenshot</th><td>"+ project.num_ss + "</td></tr>";
      html += "<tr><th>LineNotify</th><td>"+ project.line_notify + "</td></tr>";
      html += "<tr><th>MailAddress</th><td>"+ project.num_mail + "</td></tr>";
      html += "<tr><th>RandomIP</th><td>"+ project.num_random_ip + "</td></tr>";
      html += "</tbody></table>"
      let div = document.createElement("div");
      div.innerHTML = html;
      this.$swal({ content:div });
    }
  },
  filters: {
    moment (timestamp) {
      if (timestamp)
        return moment(new Date(timestamp*1000)).format('YYYY/MM/DD HH:mm');
      else
        return "";
    }
  }
}
</script>

<style lang="scss" scoped>
.ss-table {
  th, td {
    vertical-align: middle;
    text-align: center;
  }
}

a {
  color: #0d6efd!important;
  text-decoration: underline!important;
  cursor: pointer;
}
</style>